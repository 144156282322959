
import { Guid } from "guid-typescript";

export default class OperationCoefficientException{

    public id:string;
      public operationId:string;
      public operationName:string;
      public  OperationCode:string;

      public coefficientId:string;
      public  coefficientName:string;
      public  coefficientCode:string;
      public languageCode:string;
      constructor(){
          this.id = Guid.create().toString();
          this.operationId = "";
          this.operationName = "";
          this.OperationCode = "";
          this.coefficientId = "";
          this.coefficientName = "";
          this.coefficientCode = "";
          this.languageCode = "";
      }
}